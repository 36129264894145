import { highestOrderBitContent } from './highest-order-bit';
import { bestBusinessModelContent } from './best-business-model.js';

const viewport = 'width=device-width, initial-scale=1.0'

const mobileStyles = {
    fontSize: '16px', // Readable font size for mobile
    lineHeight: '1.6', // Increased line height for readability
    padding: '15px'
}


export const blogContent = {
    'highest-order-bit': {
        title: 'Highest Order Bit',
        content: highestOrderBitContent,
        meta: {
            viewport,
            mobileStyles
        }
    },
    'best-business-model': {
        title: 'Best Business Model',
        content: bestBusinessModelContent,
        meta: {
            viewport,
            mobileStyles
        }
    }
};